<template>
  <div>
    <b-table-custom ref="custom-table"
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
    >
      <template #button_event>
        <b-button size="sm" variant="primary" @click="openModal('modal-edit')">
          <i class="fas fa-edit mr-50"/>
          <span>Cập nhật</span>
        </b-button>
      </template>
      <template #cell(id)="{data}">
        <a :href="`/invoice/detail/${data['item']['id']}`">
          <strong>#{{ data['item']['id'] }}</strong>
        </a>
      </template>
      <template #cell(view_detail)="{data}">
        <b-badge
            :href="`/invoice/detail/${data['item']['id']}`"
            variant="primary"
        >
          <div class="d-flex align-items-center">
            <feather-icon
                icon="EyeIcon"
                class="mr-25"
            />
            <span>Chi tiết</span>
          </div>
        </b-badge>
      </template>
      <template #cell(total_amount)="{data}">
        <strong>{{ currencyFormat(data['item']['total_amount'] || 0) }}</strong>
      </template>
      <template #cell(total_income)="{data}">
        <strong class="text-success">{{ currencyFormat(data['item']['total_income'] || 0) }}</strong>
      </template>
      <template #cell(total_actual)="{data}">
        <strong class="text-info">{{ currencyFormat(data['item']['total_actual'] || 0, 'en-US', 'USD') }}</strong>
      </template>
      <template #cell(type)="{data}">
        <b-badge :variant="`light-${invoiceType(data['item']['type']).variant}`">
          <div class="d-flex align-items-center">
            <i class="mr-50" :class="`${invoiceType(data['item']['type']).icon}`"></i>
            <span>{{ invoiceType(data['item']['type']).label }}</span>
          </div>
        </b-badge>
      </template>
      <template #cell(status)="{data}">
        <b-badge :variant="`light-${invoiceStatus(data['item']['status']).variant}`">
          <div class="d-flex align-items-center">
            <feather-icon
                :icon="invoiceStatus(data['item']['status']).icon"
                class="mr-25"
            />
            <strong>{{ invoiceStatus(data['item']['status']).label }}</strong>
          </div>
        </b-badge>
      </template>
      <template #cell(payment_method)="{data}">
        <b-badge
            :variant="paymentMethod(data['item']['payment_method']).variant"
        >
          <div class="d-flex align-items-center">
            <div v-if="data['item']['payment_method']['type'] === null" style="width: 16px" class="text-center mr-25">
              <i :class="`${paymentMethod(data['item']['payment_method']).icon}`"></i>
            </div>
            <img v-else :src="data['item']['payment_method']['logo']" alt="payment method icon" class="mr-25" style="width: 16px"/>
            <span>{{ paymentMethod(data['item']['payment_method']).label }}</span>
          </div>
        </b-badge>
      </template>
    </b-table-custom>

    <custom-modal id="custom-modal"
                  ref="custom-modal"
                  :title="currentModal.title"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :variant="currentModal.color"
                  :is-manual-close="currentModal.manualClose"
                  @ok="currentModal.confirmAction"
    >
      <item-list :items="selectedList">
        <template #item-label="{ data }">
          <span>ID: #{{ data['id'] }} - {{ data['user']['user_name'] }} - {{ invoiceStatus(data['status'])['label'] }} - {{ currencyFormat(data['total_amount']) }}</span>
        </template>
      </item-list>

      <div v-if="modalName === 'modal-edit'">
        <span>Trạng thái hoá đơn</span>
        <v-select class="mb-1"
            v-model="currentObjUpdate['status']"
            :options="invoiceStatusList"
        />
        <payment-detail-list class="mb-1"
                             v-model="currentObjUpdate['payment_detail_id']"
                             label="Chọn phương thức thanh toán"
                             @change="paymentDetailChange"
        />
        <b-row>
          <b-col cols="12" md="6">
            <date-time-picker
                v-model="currentObjUpdate['created_at']"
                label="Ngày tạo"
            />
          </b-col>
          <b-col cols="12" md="6">
            <date-time-picker
                v-model="currentObjUpdate['updated_at']"
                label="Ngày cập nhật"
            />
          </b-col>
        </b-row>
        <span>Ghi chú</span>
        <b-textarea v-model="currentObjUpdate['note']" placeholder="Nhập ghi chú" />
      </div>
    </custom-modal>
  </div>
</template>

<script>
import BTableCustom from '@/views/components/table/BTableCustom.vue'
import CustomModal from "@/views/components/CustomModal.vue";
import PaymentDetailList from "@/views/components/static/PaymentDetailList.vue";
import DateTimePicker from "@/views/components/form/DateTimePicker.vue";

import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import dataObjectList from '@/mixins/utils/dataObjectList'
import invoiceService from "@/mixins/utils/axios/invoice.service";
import notificationHelper from "@/mixins/notificationHelper";
import ItemList from "@/views/components/ItemList.vue";
import UserList from "@/views/components/static/UserList.vue";
import vSelect from 'vue-select'

export default {
  name: 'InvoiceList',
  components: {
    vSelect,
    UserList,
    ItemList,
    BTableCustom,
    CustomModal,
    PaymentDetailList,
    DateTimePicker
  },
  mixins: [inputHelper, dataRequester, dataObjectList, notificationHelper],
  data () {
    return {
      tableColumns: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'type',
          label: 'Phân Loại'
        },
        {
          key: 'total_amount',
          label: 'Tổng tiền'
        },
        {
          key: 'total_income',
          label: 'Lợi tức'
        },
        {
          key: 'total_actual',
          label: 'Tổng thực tế'
        },
        {
          key: 'status',
          label: 'Trạng thái'
        },
        {
          key: 'payment_method',
          label: 'P.Thức thanh toán'
        },
        {
          key: 'note',
          label: 'Ghi chú'
        },
        {
          key: 'user',
          label: 'Người dùng'
        },
        {
          key: 'created_by',
          label: 'Người tạo'
        },
        {
          key: 'created_at',
          label: 'Ngày tạo'
        },
        {
          key: 'updated_at',
          label: 'Ngày cập nhật'
        },
        {
          key: 'view_detail',
          label: 'Xem chi tiết'
        }
      ],
      isDataLoading: false,
      modalName: '',
      selectedList: [],
      currentObjUpdate: {},
      invoiceStatusList: ['Unpaid', 'Pending', 'Finished', 'Canceled'],
      paymentDetailSelected: null
    }
  },
  computed: {
    invoiceType() {
      return (type) => {
        switch (type) {
          case 'Renew':
            return {
              label: 'Gia hạn',
              icon: 'fas fa-repeat',
              variant: 'success'
            }
          case 'Assign':
            return {
              label: 'Cấp mới',
              icon: 'fas fa-cart-plus',
              variant: 'primary'
            }
          case 'Topup':
            return {
              label: 'Nạp tiền',
              icon: 'fas fa-dollar-sign',
              variant: 'info'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'fas triangle-exclamation',
              variant: 'secondary'
            }
        }
      }
    },
    invoiceStatus() {
      return (status) => {
        switch (status) {
          case 'Unpaid':
            return {
              label: 'Chưa thanh toán',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          case 'Pending':
            return {
              label: 'Chờ xử lý',
              icon: 'ClockIcon',
              variant: 'warning'
            }
          case 'Finished':
            return {
              label: 'Hoàn thành',
              icon: 'CheckCircleIcon',
              variant: 'success'
            }
          case 'Canceled':
            return {
              label: 'Đã hủy',
              icon: 'XCircleIcon',
              variant: 'secondary'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'AlertTriangleIcon',
              variant: 'secondary'
            }
        }
      }
    },
    paymentMethod() {
      return (method) => {
        switch (method['type']) {
          case 'Bank':
          case 'Wallet':
          case 'Balance':
            return {
              label: method['name'],
              icon: 'fas fa-credit-card',
              variant: 'info'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'fa fa-triangle-exclamation',
              variant: 'secondary'
            }
        }
      }
    },
    currentModal () {
      const self = this
      switch (this.modalName) {
        case 'modal-edit':
          return {
            title: 'Cập nhật thông tin',
            okTitle: 'Cập nhật',
            cancelTitle: 'Đóng',
            color: 'primary',
            manualClose: true,
            confirmAction: self.updateInvoice
          }
        default:
          return {
            title: '',
            okTitle: '',
            variant: '',
            confirmAction: () => {
            }
          }
      }
    }
  },
  created() {
    this.getInvoiceList()
  },
  methods: {
    getInvoiceList () {
      this.isDataLoading = true
      this.runApi(invoiceService.listAllInvoices(), (result) => {
        this.dataObjectList = result.data
        this.isDataLoading = false
      })
    },
    openModal (modalName) {
      this.modalName = modalName
      this.$refs['custom-modal'].disableLoading()
      this.selectedList = this.getSelectedRows()

      if (this.selectedList.length === 0) {
        this.popupError("Vui lòng chọn ít nhất 1 hoá đơn để thực hiện")
        return
      }

      if (this.selectedList.length === 1) {
        this.currentObjUpdate = {
          payment_detail_id: this.selectedList[0]['payment_detail_id'],
          status: this.selectedList[0]['status'],
          created_at: this.convertToISOTime(this.selectedList[0]['created']['at']),
          updated_at: this.convertToISOTime(this.selectedList[0]['updated']['at']),
          note: this.selectedList[0]['note']
        }
      } else {
        this.currentObjUpdate = {}
      }

      this.$refs['custom-modal'].show()
    },
    closeModal () {
      this.$refs['custom-modal'].hide()
    },
    getSelectedRows () {
      return this.$refs['custom-table'].getSelectedData()
    },

    paymentDetailChange (value) {
      this.paymentDetailSelected = value
    },
    updateInvoice () {
      const self = this
      this.runApi(invoiceService.updateInvoice({
        ids: self.selectedList.map((item) => item['id']),
        ...self.currentObjUpdate
      }), (result) => {
        self.selectedList.forEach((item) => {
          item['status'] = self.currentObjUpdate['status']
          item['payment_detail_id'] = self.currentObjUpdate['payment_detail_id']
          item['created']['at'] = self.currentObjUpdate['created_at']
          item['updated']['at'] = self.currentObjUpdate['updated_at']
          item['note'] = self.currentObjUpdate['note']
          if (self.paymentDetailSelected) {
            item['payment_method']['logo'] = self.paymentDetailSelected['logo']
            item['payment_method']['name'] = self.paymentDetailSelected['short_name']
            item['payment_method']['type'] = self.paymentDetailSelected['type']
          }
        })
      }, undefined, () => {
        self.closeModal()
      }, true)
    }
  }
}

</script>

<style scoped lang="scss">

</style>
